import React from 'react';
import { Helmet } from 'react-helmet';

import TopRow from './parts/TopRow/TopRow.js';
import homepageContent from './inspHomepageContent.json';

function InspHomepage() {
  return (
    <div>
      <Helmet title={homepageContent.page_title_template} titleTemplate={homepageContent.page_title_template} />
      <TopRow homepageContent={homepageContent} />
    </div>
  );
}

export default InspHomepage;
