import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import userRoles from '../globals/userRoles';
import { isLoggedIn } from '../redux/selectors/authorization/user';
import LocationChangeListener from './locationChangeListener';
import AppLayout from './AppLayout/AppLayout';
import NotFoundView from '../views/errors/NotFoundView';
import InspIndexRedirectHandler from '../routes/inspIndexRedirectHandler.js';
// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';
import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';
import InspBooksPage from '../routes/InspBooksPage/InspBooksPage.js';
import InspEcommercePage from '../routes/InspEcommercePage/InspEcommercePage.js';

import protectedRoute from './protectedRoute';
import withAuthorization from '../authorization/withAuthorization';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset.js';
import ResetPassword from '../routes/ResetPassword/ResetPassword.js';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route path="/logged-out">
            <AppLayout>
              <LaunchTokenLogoutPage />
            </AppLayout>
          </Route>

          <Route exact path="/">
            <AppLayout>
              <InspIndexRedirectHandler />
            </AppLayout>
          </Route>

          <Route path="/books">
            <AppLayout>{withAuthorization(InspBooksPage, isLoggedIn, '/register')}</AppLayout>
          </Route>

          <Route path="/view/:bookId">
            <AppLayout embedded>{withAuthorization(InspEcommercePage, isLoggedIn, '/register')}</AppLayout>
          </Route>

          <Route path="/register">
            <AppLayout>{protectedRoute(RegisterPage, false, Object.keys(userRoles), '/books')}</AppLayout>
          </Route>

          {/* Support pages */}
          <Route path="/support/articles/:pageName?">
            <AppLayout>
              <SupportArticlePage />
            </AppLayout>
          </Route>

          <Route path="/reset-password/:token">
            <AppLayout>
              <ResetPassword />
            </AppLayout>
          </Route>

          <Route path="/reset-password">
            <AppLayout>
              <RequestPasswordReset />
            </AppLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <AppLayout>
              <NotFoundView />
            </AppLayout>
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
