import React from 'react';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

import booksPageContent from './inspBooksPageContent.json';

function InspBooksPage() {
  const btnUrl = booksPageContent.btn_url;
  const bodyText = booksPageContent.body_text.replace('{btn_url}', `<a href="${btnUrl}">${btnUrl}</a>`);

  return (
    <div>
      <Helmet title={booksPageContent.page_title_template} titleTemplate={booksPageContent.page_title_template} />
      <div className="grid grid_max_width">
        <div className="row">
          <div className="col">
            <h2>{booksPageContent.heading_text}</h2>
            <br />
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bodyText) }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InspBooksPage;
