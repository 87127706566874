import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { inspLayoutConstants } from '../globals/inspConstants.js';

import InspHomepage from './InspHomepage/InspHomepage.js';

class InspIndexRedirectHandler extends Component {
  componentWillMount() {
    const { isAuthorized } = this.props;

    if (isAuthorized) {
      const nextPage = inspLayoutConstants.PATH_NAMES.START_PAGE;
      window.location.href = nextPage;
    }
  }

  // This only happens when one of the conditions above is NOT causing a REDIRECT:
  render() {
    return <InspHomepage />;
  }
}

InspIndexRedirectHandler.propTypes = {
  isAuthorized: PropTypes.bool.isRequired
};

export default connect(state => ({
  isAuthorized: !!state.identity.role
}))(withRouter(InspIndexRedirectHandler));
