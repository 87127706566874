import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import styles from './TopRow.scss';

function TopRow({ homepageContent }) {
  const btnUrl = homepageContent.banner_btn_url;
  const bannerText = homepageContent.banner_body_text.replace(
    '{banner_btn_url}',
    `<a href="${btnUrl}" target="_blank">${btnUrl}</a>`
  );

  return (
    <div className={styles.homePageSectionOne}>
      <div className="grid grid_max_width">
        {/* Hero banner */}
        <div className="row" id="hero-banner">
          <div className="col md6">
            <div className={styles.bannerText}>
              <h1>{homepageContent.banner_heading_text}</h1>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bannerText) }} />
            </div>
          </div>

          <div className="col md6">
            <div className={styles.imageContainer}>
              <img aria-hidden="true" alt="" src={homepageContent.heroBanner1_img_src} />
              <img aria-hidden="true" alt="" src={homepageContent.heroBanner2_img_src} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TopRow.propTypes = {
  homepageContent: PropTypes.object.isRequired
};

export default TopRow;
